* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}


.wrapper {
  width: 100%;
  overflow: hidden;
}


/* --------- */
/*Scrollbar   */
/* --------- */

/* ::-webkit-scrollbar {
  width: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #fff;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #007000;
  border-radius: 1px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #238823;
  border-radius: 1px;
} */


a {
  cursor: pointer;
}

a:hover {
  color: #B76811;
}

.bannerColor {
  background-color: rgba(0,0,0,0.5);
}

.videoColor {
  position: relative;
  background-image: url(./assets/classroom.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom:50px;
  height: auto;
  
}
.videoColor::before{
  content: '';
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
}

/* slick slider */
.slick-slide img {
  height: 100vh;
  width: 100vw;
}

.slick-prev {
  margin-left: 30px;
  z-index: 5;
}
.slick-next {
  margin-right: 70px;
  z-index: 5;
}

.slick-prev, .slick-next {
  font-size: 15px !important;
}

 .slick-prev:before, .slick-next:before  {
  content: '' !important;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  top: 570px;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: solid #fff 0.15rem !important;
  border-radius: 100%;
  text-indent: -9999px;
}
.slick-dots li.slick-active button {
  background-color: #fff !important;
}


.appointmentGradientBg {
  background: #283c86;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #45a247, #283c86);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #45a247, #283c86); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


/* gallery lightbox */

  
#lightbox-img {
  height: 90vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 36, 36, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/*Completely optional styling that has nothing to do with the lightbox*/
.gallery-lightbox-btn {
  color: white;
  border: 2px solid #B76811;
  background-color: #B76811;
  font-size: 2rem;
  padding: 0px;
}
img:hover, .gallery-lightbox-btn:hover {
  cursor: pointer;
}


@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #lightbox-img {
    max-width: 80vw;
    height: auto;
  }

  .gallery-lightbox-btn {
    font-size: 1rem;
    padding: 5px;
  }
}


/* card flip */
.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  border-radius: 0.35rem;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(245, 245, 245, 0.973);
  transform-style: preserve-3d;
  transition: 150ms;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
  height: 250px;
  width: 200px;
  margin: 10px;
}

.card .flip {
  --rotate-y: 180deg;
}

.card .front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
}

.card .back {
  transform: rotateY(180deg);
}

.card .back p {
  max-width: 75px;
}
