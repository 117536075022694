@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }

  button {
    @apply text-white border-2 bg-[#B76811] border-[#B76811]
    hover:bg-[#BA7B3F] hover:text-[#fff] rounded-md
  }
}